.video-container {
    position: relative;
    padding-bottom: 56.25%;
}
  
.video-container iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
        padding-left:  0px !important;
        padding-right: 0px !important;
}

.headerpadclass {
    margin-left: 25px;
}

